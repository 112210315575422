/*--------------------
  FONTS
--------------------*/
@import "../helpers/utils";

$fontTimesNewRoman: "times new roman";
$fontArial: "Arial";
$fontBasis33: "basis33";

@include font-face($fontTimesNewRoman, "regular", 400, normal);
@include font-face($fontTimesNewRoman, "italic", 400, italic);
@include font-face($fontTimesNewRoman, "bold", 700, normal);
@include font-face($fontArial, "regular", 400, normal);
@include font-face($fontBasis33, "regular", 400, normal);
