@charset "utf-8";

// Import CSS from Leaflet and plugins.
@import '../../node_modules/leaflet/dist/leaflet.css';

// Customization

// You can easily customize Bulma with your own variables.
// Just uncomment the following block to see the result.

$grape: #4f3995;
$primary: #e9f3e599;
$dark-green: #54854b;
$dark-gray: #2e2e2e;

//$navbar-tab-hover-background-color: #a5a196;
$navbar-item-hover-background-color: #6863551c;
$navbar-item-hover-color: $grape;

// bulma-slider
$slider-radius: 5px;
$slider-track-radius: 10px;
$slider-output-width: 20px;
$slider-output-radius: 20px;

.is-hidden {
    display: none !important;
}

.leaflet-marker-pane {
    font-size: 16px;
}

.generation-marker {
    @extend .text;
    @extend .text_type_body-reg;
    width: 100%;
    min-width: 120px;
    min-height: 22px;
    text-align: center;
  }

 .generation-marker:nth-of-type(1) {
    top: 24px;
    left: -48px;
  }

.leaflet-container {
    width: 600px;
    max-width: 100%;
    height: 300px;
    max-height: 100%;
}

.leaflet-control-attribution {
    display: none;
}

.leaflet-control-zoom[class] {
    display: flex;
    flex-flow: row-reverse;
    border: em(2) solid $treeControlsBG;
    border-radius: em(2);
    box-shadow: none;

    span {
        font: bold 22px/30px Lucida Console, Monaco, monospace;
    }

    .leaflet-control-zoom-in {
        border-bottom: none;
        border-left: em(2) solid $treeControlsBG;
        border-radius: 0;
    }

    a {
        width: em(30);
        height: em(30);
        font-size: 16px;
    }
}

.leaflet-container .leaflet-control-zoom {
    display: none;
}

#map {
    margin-top: -40px;
    width: 100%;
    font-family: $fontTimesNewRoman;
    background: none;

    @include media($tabletL) {
        margin-top: -32px;
    }
}

.leaflet-tooltip-pane {
    font-size: 16px;
}
.tree-tooltip {
    @extend .text;
    @extend .text_type_body-reg;
    padding: 4px 8px;
    border: none;
    border-radius: 0;
    background: $accent2;
    box-shadow: none;
    opacity: 1;

    &:before {
        display: none;
    }
}

#waitModal {
    z-index: 999;
}

#waitModal .modal-content {
    padding: 8px;
}

html body .closeModal {
    position: relative;
    top: -10px;
    float: right;
}