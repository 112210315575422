$neutrals1: #000000;
$neutrals2: #808080;
$neutrals3: #EFEFEF;
$neutrals4: #FFFFFF;
$accent1: #660000;
$accent2: #FFFFCD;

$border: #817B58;
$windowsPlayerBG: #BFBFBF;
$windowPlayerControlColor: #828282;
$treeControlsBG: #DDDDDD;
