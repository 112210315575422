@import "../helpers/utils";

.box {
  padding: em(2);
  border: em(3) solid $neutrals2;
  background: $neutrals4;

  &__content {
    border: em(1) solid $neutrals1;
    background: $accent2;
  }
}
