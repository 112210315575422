/*--------------------
  MIXINS
--------------------*/

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin response-image {
  max-width: 100%;
  height: auto;
}


@mixin media($widthValue, $width: max-width) {
  @media only screen and ($width: $widthValue) {
    @content;
  }
}

@mixin font-face($font-name, $type, $weight, $style) {
  @font-face {
    font-weight: $weight;
    font-display: fallback;
    font-family: quote($font-name);
    font-style: $style;
    src:
      url("/src/fonts/" + $font-name + " " + $type + "/" + $font-name + " " + $type + ".woff2"),
      url("/src/fonts/" + $font-name + " " + $type + "/" + $font-name + " " + $type + ".woff"),
  }
}

@mixin font($weight, $size, $height, $family, $style: normal) {
  font: $style $weight em($size)/em($height, $size) $family;
}