@import "../helpers/utils";

.hobbies-form {
  padding: em(18) em(16);

  &__title {
    margin: 0 0 em(18) 0;
  }

  &__button-container {
    display: inline-block;
    margin: em(24) 0;
    padding: em(5) em(44);
    background:
      url("/src/images/Pallet.png") no-repeat left,
      url("/src/images/Pallet.png") no-repeat right;
  }

  &__last-p {
    margin: em(18) 0 0 0;
  }

  @include media($tabletL) {
    padding: em(18) em(10);

    &__title {
      margin: 0 0 em(12) 0;
    }

    &__button-container {
      margin: em(16) 0;
    }

    &__last-p {
      margin: em(12) 0 0 0;
    }
  }

  @include media($tabletS) {
    &__description {
      display: inline;
    }
  }
}