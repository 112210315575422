@import "../helpers/utils";

.portrait-section {
  display: flex;

  &__preview {
    flex-shrink: 1;
    margin: 0 em(40) 0 0;
    width: 100%;
    max-width: 400px;
  }

  &__description {
    max-width: 400px;
  }

  &__minting-form {
    margin: em(40) 0 0 0;
  }

  @include media($laptopS) {
    &__preview {
      margin: 0 em(24) 0 0;
      min-width: em(320);
    }

    &__description {
      flex-shrink: 1.3;
      min-width: em(380);
      max-width: 100%;
    }
  }

  @include media($tabletL) {
    &__preview {
      margin: 0 em(24) 0 0;
      min-width: em(280);
    }

    &__description {
      flex-shrink: 1.5;
      min-width: em(300);
      max-width: 100%;
    }

    &__minting-form {
      margin: em(24) 0 0 0;
    }
  }

  @include media($tabletM) {
    flex-flow: column;

    &__preview {
      margin: 0 0 em(32) 0;
      min-width: auto;
      max-width: 100%;
    }

    &__description {
      min-width: auto;
    }
  }
}