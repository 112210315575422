@import "../helpers/utils";

.checkbox {
  display: flex;
  align-items: center;
  min-height: 22px;

  &__element {
    display: block;
    box-sizing: border-box;
    width: em(14);
    height: em(14);
    border: em(1) solid $neutrals2;
    border-radius: em(1);
    background: $neutrals4 1px center;
    transition: 0.3s background ease-in-out;
    cursor: pointer;

    &:checked {
      background: $neutrals2 url("/src/images/icons/arrow.svg") no-repeat 1px center;
    }
  }

  &__label {
    margin: 0 0 0 em(8);
    cursor: pointer;
  }
}