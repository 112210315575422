@import "../helpers/utils";

.description {
  &__header {
    margin: 0 0 em(40) 0;
    text-align: center;
  }

  &__title {
    margin: 0 0 em(12) 0;
    text-transform: uppercase;
  }

  &__section {
    margin: em(18) 0 0 0;
    padding: em(18) 0 0 0;
    border-top: em(1) solid $neutrals2;
  }

  &__block {
    &:not(:first-child) {
      margin: em(18) 0 0 0;
    }
  }

  &__property {
    &:not(:first-child) {
      margin-top: em(4);
    }
  }

  &__property-name {
    margin: 0 em(8) 0 0;
  }

  @include media($tabletL) {
    &__header {
      margin: 0 0 em(24) 0;
    }
  }
}