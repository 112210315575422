@import "../helpers/utils";

.minting-form {
  text-align: center;

  &__content {
    padding: em(30) em(40);
  }

  &__button-container {
    display: inline-block;
    margin: em(32) auto;
    padding: em(5) em(44);
    background:
      url("/src/images/Coins.png") no-repeat,
      url("/src/images/Coins.png") no-repeat right;
  }

  @include media($tabletL) {
    &__content {
      padding: em(20) em(10);
    }

    &__button-container {
      margin: em(16) auto;
    }
  }
}