@import "../helpers/utils";

.tree-controls {
  position: fixed;
  right: em(40);
  bottom: em(40);
  z-index: 1000;

  &__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: em(14) em(12) em(18) em(12);
    width: em(88);
    height: em(380);
  }

  &__magnify-img {
    margin: 0 0 em(10) 0;
  }

  &__zoom-container {
    margin: 0 0 em(20) 0;
  }

  &__minimap[class][class] {
    transform: translateY(#{em(250/2 - 24/2)}) rotate(-90deg);
    width: 250px !important;
    height: em(24);
    border: none;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      width: em(22);
      height: em(22);
      background-color: $accent1;
      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      border: em(1) solid $treeControlsBG;
      background: $treeControlsBG;
    }

    &:focus {
      outline: 0 !important
    }
  }

  @include media($tabletL) {
    display: none;
  }
}