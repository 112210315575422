@import "../helpers/utils";

.contacts {
  padding: em(14) em(56);
  text-align: center;
  background:
    url("/src/images/Mail.png") no-repeat left em(16) center / em(32),
    url("/src/images/Mail.png") no-repeat right em(16) center / em(32) #FFFFCD;

  &__p {
    &:not(:first-child) {
      margin: em(4) 0 0 0;
    }
  }

  @include media($tabletL) {
    padding: em(58) em(10);
    background:
      url("/src/images/Mail.png") no-repeat center top em(16) / em(32),
      url("/src/images/Mail.png") no-repeat center bottom em(16) / em(32) #FFFFCD;

    &__p {
      &:not(:first-child) {
        margin: em(12) 0 0 0;
      }
    }
  }
}