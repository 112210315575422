@import "../helpers/utils";

.collection-section {
  &__title {
    margin: 0 0 em(40) 0;
    text-align: center;
  }

  &__fake-image {
    display: none !important;
  }

  .plyr {
    min-width: auto;
  }

  .plyr,
  &__img {
    margin: em(-6) 0 0 em(-6);
    width: calc(100% + #{em(12)});
    max-width: calc(100% + #{em(12)});
  }

  &__gallery {
    opacity: 0;
    visibility: hidden;
    transition: 0.1s opacity ease-in-out;

    &_visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    position: relative;

    &:hover {
      .collection-section__caption[class][class] {
        display: block;
      }
    }
  }

  &__link {
    display: block;
    max-height: 100%;
    overflow: hidden;
  }

  &__caption[class][class] {
    @extend .tree-tooltip;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 10;
  }

  @include media($tabletL) {
    &__title {
      margin: 0 0 em(32) 0;
      text-align: center;
    }
  }
}
