@import "../helpers/utils";

@mixin windows-player-button-border {
  border-top: em(1) solid #808080;
  border-right: em(1) solid #FFFFFF;
  border-bottom: em(1) solid #FFFFFF;
  border-left: em(1) solid #808080;
}

.windows-player-wrapper {
  border-top: em(1) solid #DFDFDF;
  border-right: em(1) solid #000000;
  border-bottom: em(1) solid #000000;
  border-left: em(1) solid #DFDFDF;

  &__content {
    position: relative;
    padding: em(2) em(2) 0 em(2);
    border-top: em(1) solid #FFFFFF;
    border-right: em(1) solid #7F7F7F;
    border-bottom: em(1) solid #7F7F7F;
    border-left: em(1) solid #FFFFFF;
    background: #BFBFBF;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  &__loader-img {
    margin: 0 auto em(10) auto;
  }

  .plyr {
    padding-bottom: em(21+3*2);
    background: none;

    &__video-wrapper {
      border-top: em(1) solid #00007F;
      border-right: em(1) solid #FFFFFF;
      border-bottom: em(1) solid #FFFFFF;
      border-left: em(1) solid #7F7F7F;
      background: none;
    }

    &__controls {
      justify-content: space-between;
      padding: em(3);
      background: $windowsPlayerBG;
      cursor: default;
    }

    &__control {
      svg {
        width: em(21);
        min-width: em(21);
        height: em(21);
        min-height: em(21);
      }

      &.plyr__play,
      &.plyr__pause,
      &.plyr__stop {
        display: flex;
        justify-content: center;
        align-items: center;
        width: em(21);
        height: em(21);
        border-radius: 0;
        transition: none;
      }

      &.plyr__pause {
        @include windows-player-button-border;
      }

      &[data-plyr="play"] {
        display: flex;
        flex-flow: row-reverse;
        padding: 0;
        cursor: pointer;

        &:not(:first-child) {
          margin: 0 0 0 em(3);
        }

        .icon--not-pressed {
          display: block;
        }

        .icon--pressed {
          display: block;
        }
      }

      &--pressed {
        &.plyr__play {
          @include windows-player-button-border;
        }

        &.plyr__pause {
          border: none;
        }
      }

      &--overlaid[class] {
        display: none;
      }

      &:hover {
        background: none;
      }
    }

    &__controls-block {
      display: flex;
    }

    &__volume {
      padding: 0;
      width: auto;
      min-width: auto;

      .icon--pressed,
      .icon--not-pressed{
        width: em(21);
        height: em(21);

        use {
          display: none;
        }
      }

      .icon--pressed {
        background: url("../images/muted1.png") no-repeat center /em(21);
      }

      .icon--not-pressed {
        background: url("../images/ummuted1.png") no-repeat center /em(21);
      }
    }

    &__stop {
      margin: 0 0 0 em(3);
      padding: 0;

      svg {
        width: em(21);
        min-width: em(21);
        height: em(21);
        min-height: em(21);
      }
    }

    &_not-started {
      .plyr__pause, .plyr__play {
        border: none;
      }

      .plyr__stop {
        @include windows-player-button-border;
      }
    }
  }

  @include media($tabletL) {
    &__loader-img {
      width: em(24);
    }
  }
}