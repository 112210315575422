@import "../helpers/utils";

.portrait {
  &__video {
    background: $accent2;

    &_vertical {
      aspect-ratio: 9/16;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: em(16) 0 0 0;

    &_only-sound-button {
      justify-content: flex-end;
    }
  }

  &__full-resolution-links {
    margin: 0 0 0 em(8);
  }

  &__volume-button {
    border: none;
    background: none;
    cursor: pointer;

    &_state {
      &_on {
        .portrait__turned-on-icon {
          display: block;
        }
      }
      &_off {
        .portrait__turned-off-icon {
          display: block;
        }
      }
    }
  }

  &__turned-on-icon {
    display: none;
    width: em(32);
  }

  &__turned-off-icon {
    display: none;
    width: em(32);
  }

  @include media($tabletL) {
    &__info {
      margin: em(8) 0 0 0;
    }

    &__turned-on-icon {
      width: em(24);
    }

    &__turned-off-icon {
      width: em(24);
    }
  }
}