@import "../helpers/utils";

.minting-painting-form {
  padding: em(18) em(16);
  text-align: center;

  &__title {
    margin: 0 0 em(18) 0;
  }

  &__description {
    letter-spacing: em(-0.5);
  }

  &__button-container {
    display: inline-block;
    margin: em(18) 0;
    padding: em(5) em(44);
    background:
      url("/src/images/Pallet.png") no-repeat left,
      url("/src/images/Pallet.png") no-repeat right;
  }

  @include media($tabletL) {
    padding: em(18) em(10);

    &__title {
      margin: 0 0 em(16) 0;
    }

    &__button-container {
      margin: em(16) 0;
    }
  }
}