@import "../helpers/utils";

.portrait-preview {
  &__tip {
    display: inline-block;
    margin: em(16) 0 0 0;
    padding: em(5) em(48) em(5) 0;
    background: url("/src/images/Eyes.png") no-repeat right;
  }

  &__img {
    width: 100%;
    aspect-ratio: 9/16;
  }
}