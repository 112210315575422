@import "../helpers/utils";

$headerBoxWidth: 875;

.header {
  position: relative;
  margin-bottom: em(40);
  z-index: 1;

  &_not-authorized {
    .header {
      &__nav-list {
        justify-content: flex-end;
      }

      &__nav-link {
        &_icon {
          &_extras {
            margin-left: em(40);
          }
        }
      }
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: em(24) 0;
  }

  &__twitter-link {
    display: block;
    padding: em(5) 0 em(5) em(40);
    background: url("/src/images/Twitter.png") no-repeat;
  }

  &__sync-container[class] {
    margin: 0;
  }

  &__sync-link {
    display: block;
    padding: em(5) em(40) em(5) 0;
    text-decoration: none;
    background: url("/src/images/Sync.png") no-repeat right;
    border: none;
    cursor: pointer;
  }

  &__box-contect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: em(110);
  }

  &__logo-link {
    display: block;
    margin: 0 0 0 pr(35, $headerBoxWidth);
  }

  &__nav {
    margin: 0 pr(60, $headerBoxWidth) 0 0;
    width: pr(406, $headerBoxWidth);
    min-width: em(350);
  }

  &__nav-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__nav-link {
    display: block;
    padding: em(48) 0 0 0;

    &_icon {
      &_tree {
        background: url("/src/images/Family tree.png") no-repeat top center;
      }
      &_collectoin {
        background: url("/src/images/Collection.png") no-repeat top center;
      }
      &_about {
        background: url("/src/images/About.png") no-repeat top center;
      }
      &_extras {
        background: url("/src/images/extras.png") no-repeat top center;
      }
    }
  }

  @include media($tabletL) {
    margin-bottom: em(32);

    &_not-authorized {
      .header__nav-list {
        justify-content: center;
      }
    }

    &__links {
      flex-flow: column;
      padding: em(16) 0;
    }

    &__twitter-link {
      order: 1;
      margin: em(12) 0 0 0;
      padding: em(2) 0 em(2) em(32);
      background: url(/src/images/Twitter.png) no-repeat left / 24px;
    }

    &__sync-link {
      padding: em(2) em(32) em(2) 0;
      background: url(/src/images/Sync.png) no-repeat right / 24px;
    }

    &__sync-container[class] {
      order: 2;
      margin: em(12) 0 0 0;
    }

    &__box-contect {
      flex-flow: column;
    }

    &__logo-link {
      margin: 0;
    }

    &__nav {
      margin: 0;
      width: 100%;
      max-width: em(500);
    }

    &__nav-list {
      display: flex;
      justify-content: space-between;
      padding: 0 em(10) em(10) em(10);
      width: 100%;
    }

    &__nav-link {
      padding: em(32) 0 0 0;

      &_icon {
        &_tree {
          background: url("/src/images/Family tree.png") no-repeat top center / em(24);
        }
        &_collectoin {
          background: url(/src/images/Collection.png) no-repeat top center / em(24);
        }
        &_about {
          background: url(/src/images/About.png) no-repeat top center / em(24);
        }
        &_extras {
          background: url("/src/images/extras.png") no-repeat top center / em(24);
        }
      }
    }
  }

  @include media($mobileL) {
    &__nav {
      min-width: auto;
    }
  }

  @include media($mobileM - 1) {
    &__nav-list {
      flex-flow: wrap;
      justify-content: center;
      margin: em(-12) 0 0 em(-12);
      width: calc(100% + #{em(12)});
    }

    &__nav-link {
      margin: em(12) 0 0 em(12);
    }
  }
}