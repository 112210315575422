@import "../helpers/utils";

.minting-painting-section {
  &__form {
    margin: 0 auto;
    max-width: em(630);
  }

  @include media($mobileL) {
    &__form {
      box-sizing: border-box;
      max-width: em(345);
    }
  }
}
