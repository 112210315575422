@import "../helpers/utils";

.page {
  padding: 0 0 em(24) 0;
  min-width: 320px;
  min-height: 100vh;
  background: url("/src/images/background.png");

  &_tree {
    display: flex;
    flex-flow: column;
    padding: 0;
    min-height: 100vh;

    .page__main {
      flex-grow: 1;

      &_for-map {
        display: flex;
        flex-flow: column;

        #map {
          flex-grow: 1;
        }
      }
    }

    .page__header {
      flex-grow: 0;
    }
  }

  @include media($tabletL) {
    padding: 0 0 em(16) 0;

    &_tree {
      padding: 0;
    }
  }
}