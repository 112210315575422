/*--------------------
  NORMALIZE
--------------------*/

@import '../../node_modules/normalize.css/normalize.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 16px;
}