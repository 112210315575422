@import "../helpers/utils";

.gallery {
  padding: em(34) em(24);
  text-align: center;

  &__title {
    display: inline-block;
    margin: 0 0 em(32) 0;
    padding: em(5) em(44);
    background:
      url("/src/images/Brush icon right.png") no-repeat left center / em(32),
      url("/src/images/Brush icon right.png") no-repeat right center / em(32);
  }

  &__items-container {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin: em(-20) 0 0 em(-8);
    width: calc(100% + #{em(8)});
  }

  &__item {
    margin: em(20) 0 0 em(8);
    max-width: em(200);
  }

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: em(200);
    max-width: em(200);
    height: em(148);
    max-height: em(148);
    cursor: default;
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }

  &__comment {
    margin: em(8) 0 0 0;
  }

  &__description {
    margin: em(40) 0 0 0;
  }

  @include media($tabletL) {
    padding: em(18) em(10);

    &__title {
      margin: 0 0 em(24) 0;
    }

    &__description {
      margin: em(24) 0 0 0;
    }
  }
}