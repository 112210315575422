@import "../helpers/utils";

.about-section {
  text-align: center;

  &__block {
    &:not(:first-child) {
      margin: em(40) auto 0 auto;
    }
  }

  &__title {
    margin: 0 0 em(18) 0;
  }

  &__p {
    &:not(:first-child) {
      margin: em(24) 0 0 0;
    }
  }

  &__contacts {
    max-width: em(552);
  }

  &__last-prase {
    max-width: em(376);
  }

  &__video {
    margin: 0 auto;
    max-width: em(640 + 6*2);
  }

  &__chainsaw-link {
    display: inline-block;
  }

  @include media($tabletL) {
    &__block {
      &:not(:first-child) {
        margin: em(32) auto 0 auto;
      }
    }

    &__p {
      &:not(:first-child) {
        margin: em(16) 0 0 0;
      }
    }

    &__contacts {
      max-width: em(344);
    }
  }

  @include media($tabletM) {
    &__author {
      margin-right: auto;
      margin-left: auto;
      max-width: em(304);
    }

    &__contacts-by {
      margin-right: auto;
      margin-left: auto;
      max-width: em(290);
    }

    &__calligraphy[class][class] {
      margin-right: auto;
      margin-left: auto;
      max-width: em(280);
    }

    &__illustrations {
      margin-right: auto;
      margin-left: auto;
      max-width: em(250);
    }
  }
}
