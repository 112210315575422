@import "../helpers/utils";

$photosContainer: 874;

.photos {
  padding: em(34) em(76);
  text-align: center;

  &__title {
    display: inline-block;
    margin: 0 0 em(32) 0;
    padding: em(5) em(44);
    background:
      url("/src/images/Camera icon right.png") no-repeat left center / em(32),
      url("/src/images/Camera icon right.png") no-repeat right center / em(32);
  }

  &__gallery {
    display: flex;
    flex-flow: wrap;
    margin: em(-40) 0 0 em(-40);
    width: calc(100% + #{em(40)});
  }

  &__item {
    margin: em(40) 0 0 em(40);
    max-width: em(120);
    max-height: em(120);
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
  }

  &__description {
    margin: em(40) 0 0 0;
  }

  @include media($laptopS) {
    &__gallery {
      justify-content: center;
    }
  }

  @include media($tabletL) {
    padding: em(18) em(10);

    &__title {
      margin: 0 0 em(24) 0;
    }

    &__description {
      margin: em(24) 0 0 0;
    }

    &__gallery {
      margin: em(-20) 0 0 em(-20);
      width: calc(100% + #{em(20)});
    }

    &__item {
      margin: em(20) 0 0 em(20);
    }
  }

  @include media($tabletS) {
    &__gallery {
      flex-flow: column;
      align-items: center;
    }
  }
}