@import "../helpers/utils";

.button {
  @extend .text;
  @extend .text_type_button;

  $fontSize: 14;
  padding: em(1, $fontSize) em(5, $fontSize);
  border: em(1, $fontSize) solid $neutrals2;
  border-radius: em(1, $fontSize);
  background: $neutrals3;
  cursor: pointer;
}