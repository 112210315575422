@import "../helpers/utils";

.painting-section {
  text-align: center;

  &__img {
    width: 100%;
  }

  &__title {
    margin: em(24) 0 em(8) 0;
  }

  &__view-link {
    margin: em(8) 0 0 0;
  }

  @include media($tabletL) {
    &__title {
      margin: em(18) 0 em(8) 0;
    }
  }
}
