/* --------------------
  VARIABLES
-------------------- */

// COLOR
@import "colors";

// FONT

// MEDIA
$mobileS: 320px;
$media360: 360px;
$mobileM: 375px;
$mobileL: 425px;
$tabletS: 480px;
$tabletM: 640px;
$tabletL: 768px;
$laptopS: 992px;
$laptopM: 1024px;
$laptopL: 1200px;
$laptopXL: 1400px;

// CONTAINERS
$screen: 1920;
$container: 885;
//
//$mobileScreen: 375;
//$mobileContainer: 343;
