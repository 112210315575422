/*--------------------
  TEXT
--------------------*/
@import "../helpers/utils";

.text {
  color: $neutrals1;

  &_type {
    &_h1 {
      @include font(400, 52, 60, $fontTimesNewRoman);
    }

    &_h2 {
      @include font(700, 24, 28, $fontTimesNewRoman);
    }

    &_body-reg {
      @include font(400, 18, 22, $fontTimesNewRoman);
    }

    &_body-link {
      @include font(400, 18, 22, $fontTimesNewRoman);
      text-decoration: underline;
      cursor: pointer;
    }

    &_body-italic-link {
      @include font(400, 18, 22, $fontTimesNewRoman, italic);
      text-decoration: underline;
      cursor: pointer;
    }

    &_body-bold {
      @include font(700, 18, 22, $fontTimesNewRoman);
    }

    &_body-italic {
      @include font(400, 18, 22, $fontTimesNewRoman, italic);
    }

    &_button {
      @include font(400, 14, 18, $fontArial);
    }

    &_pixeled {
      @include font(400, 16, 16, $fontBasis33);
    }
  }

  &_accented {
    color: $accent1;
  }

  &_no-cursor {
    cursor: default;
  }

  &_underlined {
    text-decoration: underline;
  }

  &_breakable {
    word-break: break-all;
  }

  @include media($tabletL) {
    &_type {
      &_h1 {
        @include font(400, 32, 36, $fontTimesNewRoman);
      }

      &_h2 {
        @include font(700, 20, 24, $fontTimesNewRoman);
      }

      &_body-reg {
        @include font(400, 16, 20, $fontTimesNewRoman);
      }

      &_body-link {
        @include font(400, 16, 20, $fontTimesNewRoman);
      }

      &_body-bold {
        @include font(700, 16, 20, $fontTimesNewRoman);
      }

      &_body-italic {
        @include font(400, 16, 20, $fontTimesNewRoman, italic);
      }
    }
  }
}