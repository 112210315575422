/*--------------------
  COMMON STYLES
--------------------*/
@import "../helpers/utils";

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: initial;
}

p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: vertical;
}

input,
select,
textarea,
button {
  padding: 0;
  font: inherit;
  background-clip: padding-box;
  border: 1px solid #666666;
  border-radius: 0;
  outline: none;
  appearance: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}
