/*--------------------
  INDEX STYLES
--------------------*/

// HELPERS
// Variables and mixins
@import "helpers/utils";

// LIBS
// external libraries
@import "libs/normalize";
@import "../../node_modules/plyr/dist/plyr.css";

// BASE
// Base styles
@import "base/fonts";
@import "base/common";

@import 'main';

// COMPONENTS
// Specific components like a slider, a loader, a widget
@import "components/text";
@import "components/box";
@import "components/container";
@import "components/button";
@import "components/checkbox";
@import "components/checkbox-list";
@import "components/additional-section";
@import "components/windows-player-wrapper";
@import "components/tree-controls";

// MODULES
// Modules of the layout
@import "modules/header";
@import "modules/description";
@import "modules/minting-form";
@import "modules/portrait-preview";
@import "modules/hobbies-form";
@import "modules/portrait";
@import "modules/minting-painting-form";
@import "modules/contacts";
@import "modules/photos";
@import "modules/gallery";
@import "modules/entry";
@import "modules/portrait-loader";

// SECTIONS
// Sections of the layout
@import "sections/portrait-section";
@import "sections/hobbies-section";
@import "sections/minting-painting-section";
@import "sections/painting-section";
@import "sections/collection-section";
@import "sections/about-section";
@import "sections/extras-section";

@import "components/page";

.linage {
  fill: none;
  stroke: $border;
  stroke-width: 4px;
  stroke-linejoin: round;
}

.marriage {
  fill: none;
  stroke: $border;
  stroke-width: 4px;

}

.nodeText{
  font: 10px sans-serif;
}
.marriageNode {
  border-radius: 50%;
  background-color: black;
}

.graph {
  .gallery-player {
    width: 100%;
  }

  .node {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content, .spouse {
    max-width: 150px;
  }

  .graph-image-wrapper, .graph-player {
    width: auto;
    min-width: auto;
    max-width: calc(100%);
    max-height: calc(100%);
    border: 4px solid $border;
    border-radius: 2px;
    background: $accent2;
  }

  .plyr__video-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .plyr {
    min-width: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;

    video {
      width: auto;
      height: auto;
    }
  }

  #graph-data {
    position: absolute;
    left: -400vw;
    visibility: hidden;
    z-index: -1;
  }
}