@import "../helpers/utils";

.extras-section {
  &__title {
    margin: 0 0 em(24) 0;
  }

  &__text-block {
    text-align: center;
  }

  &__article {
    &:not(:first-child) {
      margin: em(40) 0 0 0;
    }
  }

  &__block {
    &:not(:first-child) {
      margin: em(24) 0 0 0;
    }
  }

  &__p {
    &:not(:first-child) {
      margin: em(18) 0 0 0;
    }
  }

  &__centered-img[class][class] {
    margin-right: auto;
    margin-left: auto;
  }

  &__music-link,
  &__video-link {
    display: inline-block;
    padding: em(5) 0 em(5) em(40);
  }

  &__music-link {
    background: url("/src/images/Music icon.png") no-repeat left center / em(32);
  }

  &__video-link {
    background: url("/src/images/Movie icon.png") no-repeat left center / em(32);
  }

  @include media($tabletL) {
    &__article {
      &:not(:first-child) {
        margin: em(32) 0 0 0;
      }
    }

    &__block {
      &:not(:first-child) {
        margin: em(16) 0 0 0;
      }
    }
  }
}