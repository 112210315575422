@import "../helpers/utils";

.entry {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    margin: em(50) auto;
    display: block;
    max-width: pr(323, 343);
  }

  &__portrait {
    width: 100%;
  }

  &__enter-block {
    margin: em(44) auto 0 auto;
    text-align: center;
  }
}