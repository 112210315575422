@import "../helpers/utils";

.additional-section {
  margin-top: em(40);
  padding: em(40) 0 0 0;
  border-top: em(1) solid $neutrals2;

  @include media($tabletL) {
    margin-top: em(32);
    padding: em(32) 0 0 0;
  }
}
