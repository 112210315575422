html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  text-shadow: none;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  transition: box-shadow .3s;
  display: flex;
  position: relative;
}

.plyr audio, .plyr iframe, .plyr video {
  height: 100%;
  width: 100%;
  display: block;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui :after, .plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: 2px;
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: #fff;
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  padding: 3px 4px;
  line-height: 1;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  text-align: center;
  width: 100%;
  transition: transform .4s ease-in-out;
  animation: plyr-fade-in .3s;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: 20px;
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4) );
}

.plyr__caption {
  background: #000c;
  background: var(--plyr-captions-background, #000c);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #fff;
  color: var(--plyr-captions-text-color, #fff);
  white-space: pre-wrap;
  border-radius: 2px;
  padding: .2em .5em;
  line-height: 185%;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  border-radius: 3px;
  border-radius: var(--plyr-control-radius, 3px);
  color: inherit;
  cursor: pointer;
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
  background: none;
  border: 0;
  flex-shrink: 0;
  transition: all .3s;
  position: relative;
  overflow: visible;
}

.plyr__control svg {
  fill: currentColor;
  height: 18px;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: 18px;
  width: var(--plyr-control-icon-size, 18px);
  display: block;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed, a.plyr__control:after, a.plyr__control:before {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.plyr__controls .plyr__progress__container {
  min-width: 0;
  flex: 1;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr [data-plyr="airplay"], .plyr [data-plyr="captions"], .plyr [data-plyr="fullscreen"], .plyr [data-plyr="pip"], .plyr__controls:empty {
  display: none;
}

.plyr--airplay-supported [data-plyr="airplay"], .plyr--captions-enabled [data-plyr="captions"], .plyr--fullscreen-enabled [data-plyr="fullscreen"], .plyr--pip-supported [data-plyr="pip"] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  background: #ffffffe6;
  background: var(--plyr-menu-background, #ffffffe6);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px #00000026);
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  text-align: left;
  white-space: nowrap;
  z-index: 3;
  border-radius: 4px;
  margin-bottom: 10px;
  animation: plyr-popup .2s;
  position: absolute;
  bottom: 100%;
  right: -3px;
}

.plyr__menu__container > div {
  transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.plyr__menu__container:after {
  border: 4px solid #0000;
  border-top-color: #ffffffe6;
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: var(--plyr-menu-background, #ffffffe6);
  content: "";
  height: 0;
  right: 14px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7 - var(--plyr-menu-arrow-size, 4px) / 2);
  width: 0;
  position: absolute;
  top: 100%;
}

.plyr__menu__container [role="menu"] {
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container [role="menuitem"], .plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child, .plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: 4.66667px 10.5px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7 / 1.5) calc(var(--plyr-control-spacing, 10px) * .7 * 1.5);
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  align-items: center;
  display: flex;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control:after {
  border: 4px solid #0000;
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
  padding-right: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: 6.5px;
  right: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus:after, .plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: 7px;
  margin: calc(var(--plyr-control-spacing, 10px) * .7);
  margin-bottom: 3.5px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  padding-left: 28px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
  width: calc(100% - 14px);
  width: calc(100% - var(--plyr-control-spacing, 10px) * .7 * 2);
  position: relative;
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: 6.5px;
  left: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--back:before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  height: 1px;
  margin-top: 3.5px;
  margin-top: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus:after, .plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: 7px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after, .plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  content: "";
  height: 16px;
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  width: 16px;
  background: #0000001a;
  flex-shrink: 0;
  transition: all .3s;
  display: block;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  height: 6px;
  opacity: 0;
  width: 6px;
  background: #fff;
  border: 0;
  transition: transform .3s, opacity .3s;
  top: 50%;
  left: 12px;
  transform: translateY(-50%)scale(0);
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:before {
  background: #00b2ff;
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:after {
  opacity: 1;
  transform: translateY(-50%)scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"].plyr__tab-focus:before, .plyr__menu__container .plyr__control[role="menuitemradio"]:hover:before {
  background: #23282f1a;
}

.plyr__menu__container .plyr__menu__value {
  margin-left: auto;
  margin-right: calc(2 - 7px);
  margin-right: calc(var(--plyr-control-spacing, 10px) * .7 * -1 - -2);
  padding-left: 24.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 3.5);
  pointer-events: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.plyr--full-ui input[type="range"] {
  appearance: none;
  border-radius: 26px;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: #00b2ff;
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  height: 19px;
  height: calc(var(--plyr-range-thumb-active-shadow-width, 3px) * 2 + var(--plyr-range-thumb-height, 13px));
  min-width: 0;
  width: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s;
  display: block;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, currentColor 0, #0000 0);
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  margin-top: -4px;
  margin-top: calc((var(--plyr-range-thumb-height, 13px)  - var(--plyr-range-track-height, 5px)) / 2 * -1);
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  background: currentColor;
}

.plyr--full-ui input[type="range"]::-ms-track {
  color: #0000;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper, .plyr--full-ui input[type="range"]::-ms-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: currentColor;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  margin-top: 0;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-moz-range-track {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-ms-track {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

.plyr__poster {
  background-color: #000;
  background-color: var(--plyr-video-background, var(--plyr-video-background, #000));
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: #ffffffe6;
  background: var(--plyr-tooltip-background, #ffffffe6);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  color: #4a5464;
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  padding: 5px 7.5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(var(--plyr-control-spacing, 10px) / 2 * 1.5);
  pointer-events: none;
  transform-origin: 50% 100%;
  white-space: nowrap;
  z-index: 2;
  line-height: 1.3;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 10px)scale(.8);
}

.plyr__tooltip:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid #ffffffe6;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #ffffffe6);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  width: 0;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%)scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  transform-origin: 0 100%;
  left: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
  left: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  transform-origin: 100% 100%;
  left: auto;
  right: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0)scale(1);
}

.plyr__progress {
  left: 6.5px;
  left: calc(var(--plyr-range-thumb-height, 13px) * .5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}

.plyr__progress input[type="range"], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  margin-right: -6.5px;
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  width: calc(100% + 13px);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}

.plyr__progress input[type="range"] {
  z-index: 2;
  position: relative;
}

.plyr__progress .plyr__tooltip {
  max-width: 120px;
  overflow-wrap: break-word;
  white-space: normal;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  margin-top: -2.5px;
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  background: none;
  border: 0;
  border-radius: 100px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: none;
}

.plyr__progress__buffer::-webkit-progress-value {
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  transition: width .2s;
}

.plyr__progress__buffer::-moz-progress-bar {
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  transition: width .2s;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s;
}

.plyr--loading .plyr__progress__buffer {
  background-image: linear-gradient(-45deg, #23282f99 25%, #0000 0 50%, #23282f99 0 75%, #0000 0, #0000);
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, #23282f99) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, #23282f99) 50%, var(--plyr-progress-loading-background, #23282f99) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: #0000;
  animation: plyr-progress 1s linear infinite;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: #ffffff40;
  background-color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr__progress__marker {
  background-color: #fff;
  background-color: var(--plyr-progress-marker-background, #fff);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  width: 3px;
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
  border-radius: 1px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.plyr__volume {
  max-width: 110px;
  min-width: 80px;
  width: 20%;
  align-items: center;
  display: flex;
  position: relative;
}

.plyr__volume input[type="range"] {
  margin-left: 5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: 5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  z-index: 2;
  position: relative;
}

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: #4a5464;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: #fff;
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--audio .plyr__progress__buffer {
  color: #c1c8d199;
  color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr--video {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16 / 9;
}

@supports not (aspect-ratio:16/9) {
  .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  height: 100%;
  width: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.2813%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(transparent, #000000bf));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  padding: 5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: 20px;
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  z-index: 3;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: 35px;
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: #fff;
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  opacity: .9;
  padding: 15px;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  z-index: 2;
  border: 0;
  border-radius: 100%;
  transition: all .3s;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plyr__control--overlaid svg {
  position: relative;
  left: 2px;
}

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--video .plyr__progress__buffer {
  color: #ffffff40;
  color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr:-webkit-full-screen {
  height: 100%;
  width: 100%;
  background: #000;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:fullscreen {
  height: 100%;
  width: 100%;
  background: #000;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  height: 100%;
  width: 100%;
  z-index: 10000000;
  background: #000;
  margin: 0;
  display: block;
  position: fixed;
  inset: 0;
  border-radius: 0 !important;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  cursor: pointer;
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  width: 100%;
  position: absolute;
}

.plyr__ads:after {
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  pointer-events: none;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
  background: #23282f;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  opacity: .8;
  width: 3px;
  z-index: 3;
  background: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plyr__preview-thumb {
  background-color: #ffffffe6;
  background-color: var(--plyr-tooltip-background, #ffffffe6);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 100%;
  z-index: 2;
  padding: 3px;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  transform: translateY(10px)scale(.8);
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0)scale(1);
}

.plyr__preview-thumb:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid #ffffffe6;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #ffffffe6);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: calc(50% + var(--preview-arrow-offset));
  width: 0;
  z-index: 2;
  position: absolute;
  transform: translateX(-50%);
}

.plyr__preview-thumb__image-container {
  border-radius: 4px;
  border-radius: calc(var(--plyr-tooltip-radius, 5px)  - 1px);
  z-index: 0;
  background: #c1c8d1;
  position: relative;
  overflow: hidden;
}

.plyr__preview-thumb__image-container img, .plyr__preview-thumb__image-container:after {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  box-shadow: inset 0 0 0 1px #00000026;
}

.plyr__preview-thumb__image-container img {
  max-height: none;
  max-width: none;
}

.plyr__preview-thumb__time-container {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(transparent, #000000bf));
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: calc(var(--plyr-tooltip-radius, 5px)  - 1px);
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: calc(var(--plyr-tooltip-radius, 5px)  - 1px);
  z-index: 3;
  padding: 20px 6px 6px;
  line-height: 1.1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  filter: blur(1px);
  height: 100%;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  z-index: 1;
  margin: auto;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  height: 100%;
  max-height: none;
  max-width: none;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px !important;
  width: 1px !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
}

.plyr [hidden] {
  display: none !important;
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  width: 26px;
  height: 26px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  width: 36px;
  height: 36px;
  background-image: url("layers.760a0456.png");
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-control-attribution svg {
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  background: #ffffff80;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
  overflow: hidden;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  pointer-events: none;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  pointer-events: auto;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  width: 24px;
  height: 24px;
  color: #757575;
  background: none;
  border: none;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: 16px;
}

@font-face {
  font-weight: 400;
  font-display: fallback;
  font-family: times new roman;
  font-style: normal;
  src: url("times new roman regular.97e1c263.woff2"), url("times new roman regular.162e96c7.woff");
}

@font-face {
  font-weight: 400;
  font-display: fallback;
  font-family: times new roman;
  font-style: italic;
  src: url("times new roman italic.f5472517.woff2"), url("times new roman italic.4f321955.woff");
}

@font-face {
  font-weight: 700;
  font-display: fallback;
  font-family: times new roman;
  font-style: normal;
  src: url("times new roman bold.bf199918.woff2"), url("times new roman bold.d63b473d.woff");
}

@font-face {
  font-weight: 400;
  font-display: fallback;
  font-family: Arial;
  font-style: normal;
  src: url("Arial regular.175104fb.woff2"), url("Arial regular.6fa33ebc.woff");
}

@font-face {
  font-weight: 400;
  font-display: fallback;
  font-family: basis33;
  font-style: normal;
  src: url("basis33 regular.be57167f.woff2"), url("basis33 regular.b69131ff.woff");
}

*, :after, :before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: initial;
  margin: 0;
}

p {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

address {
  font-style: normal;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

textarea {
  resize: vertical;
}

input, select, textarea, button {
  font: inherit;
  appearance: none;
  background-clip: padding-box;
  border: 1px solid #666;
  border-radius: 0;
  outline: none;
  padding: 0;
}

ul, ol, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.is-hidden {
  display: none !important;
}

.leaflet-marker-pane {
  font-size: 16px;
}

.generation-marker {
  width: 100%;
  min-width: 120px;
  min-height: 22px;
  text-align: center;
}

.generation-marker:nth-of-type(1) {
  top: 24px;
  left: -48px;
}

.leaflet-container {
  width: 600px;
  max-width: 100%;
  height: 300px;
  max-height: 100%;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-control-zoom[class] {
  box-shadow: none;
  border: .125em solid #ddd;
  border-radius: .125em;
  flex-flow: row-reverse;
  display: flex;
}

.leaflet-control-zoom[class] span {
  font: bold 22px / 30px Lucida Console, Monaco, monospace;
}

.leaflet-control-zoom[class] .leaflet-control-zoom-in {
  border-bottom: none;
  border-left: .125em solid #ddd;
  border-radius: 0;
}

.leaflet-control-zoom[class] a {
  width: 1.875em;
  height: 1.875em;
  font-size: 16px;
}

.leaflet-container .leaflet-control-zoom {
  display: none;
}

#map {
  width: 100%;
  background: none;
  margin-top: -40px;
  font-family: times new roman;
}

@media only screen and (max-width: 768px) {
  #map {
    margin-top: -32px;
  }
}

.leaflet-tooltip-pane {
  font-size: 16px;
}

.tree-tooltip, .collection-section__caption[class][class] {
  box-shadow: none;
  opacity: 1;
  background: #ffffcd;
  border: none;
  border-radius: 0;
  padding: 4px 8px;
}

.tree-tooltip:before, .collection-section__caption[class]:before {
  display: none;
}

#waitModal {
  z-index: 999;
}

#waitModal .modal-content {
  padding: 8px;
}

html body .closeModal {
  float: right;
  position: relative;
  top: -10px;
}

.text, .button, .generation-marker, .tree-tooltip, .collection-section__caption[class][class] {
  color: #000;
}

.text_type_h1 {
  font: 400 3.25em / 1.15385em times new roman;
}

.text_type_h2 {
  font: 700 1.5em / 1.16667em times new roman;
}

.text_type_body-reg, .generation-marker, .tree-tooltip, .collection-section__caption[class][class] {
  font: 400 1.125em / 1.22222em times new roman;
}

.text_type_body-link {
  cursor: pointer;
  font: 400 1.125em / 1.22222em times new roman;
  text-decoration: underline;
}

.text_type_body-italic-link {
  cursor: pointer;
  font: italic 400 1.125em / 1.22222em times new roman;
  text-decoration: underline;
}

.text_type_body-bold {
  font: 700 1.125em / 1.22222em times new roman;
}

.text_type_body-italic {
  font: italic 400 1.125em / 1.22222em times new roman;
}

.text_type_button, .button {
  font: 400 .875em / 1.28571em Arial;
}

.text_type_pixeled {
  font: 400 1em / 1em basis33;
}

.text_accented {
  color: #600;
}

.text_no-cursor {
  cursor: default;
}

.text_underlined {
  text-decoration: underline;
}

.text_breakable {
  word-break: break-all;
}

@media only screen and (max-width: 768px) {
  .text_type_h1 {
    font: 400 2em / 1.125em times new roman;
  }

  .text_type_h2 {
    font: 700 1.25em / 1.2em times new roman;
  }

  .text_type_body-reg, .generation-marker, .tree-tooltip, .collection-section__caption[class][class], .text_type_body-link {
    font: 400 1em / 1.25em times new roman;
  }

  .text_type_body-bold {
    font: 700 1em / 1.25em times new roman;
  }

  .text_type_body-italic {
    font: italic 400 1em / 1.25em times new roman;
  }
}

.box {
  background: #fff;
  border: .1875em solid gray;
  padding: .125em;
}

.box__content {
  background: #ffffcd;
  border: .0625em solid #000;
}

.container {
  width: 885px;
  max-width: calc(100% - 2em);
  margin: 0 auto;
}

.button {
  cursor: pointer;
  background: #efefef;
  border: .0714286em solid gray;
  border-radius: .0714286em;
  padding: .0714286em .357143em;
}

.checkbox {
  min-height: 22px;
  align-items: center;
  display: flex;
}

.checkbox__element {
  box-sizing: border-box;
  width: .875em;
  height: .875em;
  cursor: pointer;
  background: #fff 1px;
  border: .0625em solid gray;
  border-radius: .0625em;
  transition: background .3s ease-in-out;
  display: block;
}

.checkbox__element:checked {
  background: gray url("arrow.6403aac3.svg") 1px no-repeat;
}

.checkbox__label {
  cursor: pointer;
  margin: 0 0 0 .5em;
}

.checkbox-list__item:not(:first-child) {
  margin: .25em 0 0;
}

.additional-section {
  border-top: .0625em solid gray;
  margin-top: 2.5em;
  padding: 2.5em 0 0;
}

@media only screen and (max-width: 768px) {
  .additional-section {
    margin-top: 2em;
    padding: 2em 0 0;
  }
}

.windows-player-wrapper {
  border: .0625em solid #000;
  border-color: #dfdfdf #000 #000 #dfdfdf;
}

.windows-player-wrapper__content {
  background: #bfbfbf;
  border: .0625em solid #7f7f7f;
  border-color: #fff #7f7f7f #7f7f7f #fff;
  padding: .125em .125em 0;
  position: relative;
}

.windows-player-wrapper__loader {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.windows-player-wrapper__loader-img {
  margin: 0 auto .625em;
}

.windows-player-wrapper .plyr {
  background: none;
  padding-bottom: 1.6875em;
}

.windows-player-wrapper .plyr__video-wrapper {
  background: none;
  border: .0625em solid #fff;
  border-color: #00007f #fff #fff #7f7f7f;
}

.windows-player-wrapper .plyr__controls {
  cursor: default;
  background: #bfbfbf;
  justify-content: space-between;
  padding: .1875em;
}

.windows-player-wrapper .plyr__control svg {
  width: 1.3125em;
  min-width: 1.3125em;
  height: 1.3125em;
  min-height: 1.3125em;
}

.windows-player-wrapper .plyr__control.plyr__play, .windows-player-wrapper .plyr__control.plyr__pause, .windows-player-wrapper .plyr__control.plyr__stop {
  width: 1.3125em;
  height: 1.3125em;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  transition: none;
  display: flex;
}

.windows-player-wrapper .plyr__control.plyr__pause {
  border: .0625em solid #fff;
  border-color: gray #fff #fff gray;
}

.windows-player-wrapper .plyr__control[data-plyr="play"] {
  cursor: pointer;
  flex-flow: row-reverse;
  padding: 0;
  display: flex;
}

.windows-player-wrapper .plyr__control[data-plyr="play"]:not(:first-child) {
  margin: 0 0 0 .1875em;
}

.windows-player-wrapper .plyr__control[data-plyr="play"] .icon--not-pressed, .windows-player-wrapper .plyr__control[data-plyr="play"] .icon--pressed {
  display: block;
}

.windows-player-wrapper .plyr__control--pressed.plyr__play {
  border: .0625em solid #fff;
  border-color: gray #fff #fff gray;
}

.windows-player-wrapper .plyr__control--pressed.plyr__pause {
  border: none;
}

.windows-player-wrapper .plyr__control--overlaid[class] {
  display: none;
}

.windows-player-wrapper .plyr__control:hover {
  background: none;
}

.windows-player-wrapper .plyr__controls-block {
  display: flex;
}

.windows-player-wrapper .plyr__volume {
  width: auto;
  min-width: auto;
  padding: 0;
}

.windows-player-wrapper .plyr__volume .icon--pressed, .windows-player-wrapper .plyr__volume .icon--not-pressed {
  width: 1.3125em;
  height: 1.3125em;
}

.windows-player-wrapper .plyr__volume .icon--pressed use, .windows-player-wrapper .plyr__volume .icon--not-pressed use {
  display: none;
}

.windows-player-wrapper .plyr__volume .icon--pressed {
  background: url("muted1.d20fa91e.png") center / 1.3125em no-repeat;
}

.windows-player-wrapper .plyr__volume .icon--not-pressed {
  background: url("ummuted1.80f2d3a6.png") center / 1.3125em no-repeat;
}

.windows-player-wrapper .plyr__stop {
  margin: 0 0 0 .1875em;
  padding: 0;
}

.windows-player-wrapper .plyr__stop svg {
  width: 1.3125em;
  min-width: 1.3125em;
  height: 1.3125em;
  min-height: 1.3125em;
}

.windows-player-wrapper .plyr_not-started .plyr__pause, .windows-player-wrapper .plyr_not-started .plyr__play {
  border: none;
}

.windows-player-wrapper .plyr_not-started .plyr__stop {
  border: .0625em solid #fff;
  border-color: gray #fff #fff gray;
}

@media only screen and (max-width: 768px) {
  .windows-player-wrapper__loader-img {
    width: 1.5em;
  }
}

.tree-controls {
  z-index: 1000;
  position: fixed;
  bottom: 2.5em;
  right: 2.5em;
}

.tree-controls__content {
  width: 5.5em;
  height: 23.75em;
  flex-flow: column;
  align-items: center;
  padding: .875em .75em 1.125em;
  display: flex;
}

.tree-controls__magnify-img {
  margin: 0 0 .625em;
}

.tree-controls__zoom-container {
  margin: 0 0 1.25em;
}

.tree-controls__minimap[class][class] {
  height: 1.5em;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  transform: translateY(7.0625em)rotate(-90deg);
  width: 250px !important;
}

.tree-controls__minimap[class][class]::-webkit-slider-thumb {
  width: 1.375em;
  height: 1.375em;
  -webkit-appearance: none;
  background-color: #600;
}

.tree-controls__minimap[class][class]::-webkit-slider-runnable-track {
  background: #ddd;
  border: .0625em solid #ddd;
}

.tree-controls__minimap[class][class]:focus {
  outline: 0 !important;
}

@media only screen and (max-width: 768px) {
  .tree-controls {
    display: none;
  }
}

.header {
  z-index: 1;
  margin-bottom: 2.5em;
  position: relative;
}

.header_not-authorized .header__nav-list {
  justify-content: flex-end;
}

.header_not-authorized .header__nav-link_icon_extras {
  margin-left: 2.5em;
}

.header__links {
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 0;
  display: flex;
}

.header__twitter-link {
  background: url("Twitter.06c35da5.png") no-repeat;
  padding: .3125em 0 .3125em 2.5em;
  display: block;
}

.header__sync-container[class] {
  margin: 0;
}

.header__sync-link {
  cursor: pointer;
  background: url("Sync.e9ee6eea.png") 100% no-repeat;
  border: none;
  padding: .3125em 2.5em .3125em 0;
  text-decoration: none;
  display: block;
}

.header__box-contect {
  min-height: 6.875em;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__logo-link {
  margin: 0 0 0 4%;
  display: block;
}

.header__nav {
  width: 46.4%;
  min-width: 21.875em;
  margin: 0 6.85714% 0 0;
}

.header__nav-list {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.header__nav-link {
  padding: 3em 0 0;
  display: block;
}

.header__nav-link_icon_tree {
  background: url("Family tree.bdf51672.png") top no-repeat;
}

.header__nav-link_icon_collectoin {
  background: url("Collection.1347d69f.png") top no-repeat;
}

.header__nav-link_icon_about {
  background: url("About.9347a349.png") top no-repeat;
}

.header__nav-link_icon_extras {
  background: url("extras.c415bf8a.png") top no-repeat;
}

@media only screen and (max-width: 768px) {
  .header {
    margin-bottom: 2em;
  }

  .header_not-authorized .header__nav-list {
    justify-content: center;
  }

  .header__links {
    flex-flow: column;
    padding: 1em 0;
  }

  .header__twitter-link {
    background: url("Twitter.06c35da5.png") 0 / 24px no-repeat;
    order: 1;
    margin: .75em 0 0;
    padding: .125em 0 .125em 2em;
  }

  .header__sync-link {
    background: url("Sync.e9ee6eea.png") 100% / 24px no-repeat;
    padding: .125em 2em .125em 0;
  }

  .header__sync-container[class] {
    order: 2;
    margin: .75em 0 0;
  }

  .header__box-contect {
    flex-flow: column;
  }

  .header__logo-link {
    margin: 0;
  }

  .header__nav {
    width: 100%;
    max-width: 31.25em;
    margin: 0;
  }

  .header__nav-list {
    width: 100%;
    justify-content: space-between;
    padding: 0 .625em .625em;
    display: flex;
  }

  .header__nav-link {
    padding: 2em 0 0;
  }

  .header__nav-link_icon_tree {
    background: url("Family tree.bdf51672.png") top / 1.5em no-repeat;
  }

  .header__nav-link_icon_collectoin {
    background: url("Collection.1347d69f.png") top / 1.5em no-repeat;
  }

  .header__nav-link_icon_about {
    background: url("About.9347a349.png") top / 1.5em no-repeat;
  }

  .header__nav-link_icon_extras {
    background: url("extras.c415bf8a.png") top / 1.5em no-repeat;
  }
}

@media only screen and (max-width: 425px) {
  .header__nav {
    min-width: auto;
  }
}

@media only screen and (max-width: 374px) {
  .header__nav-list {
    width: calc(100% + .75em);
    flex-flow: wrap;
    justify-content: center;
    margin: -.75em 0 0 -.75em;
  }

  .header__nav-link {
    margin: .75em 0 0 .75em;
  }
}

.description__header {
  text-align: center;
  margin: 0 0 2.5em;
}

.description__title {
  text-transform: uppercase;
  margin: 0 0 .75em;
}

.description__section {
  border-top: .0625em solid gray;
  margin: 1.125em 0 0;
  padding: 1.125em 0 0;
}

.description__block:not(:first-child) {
  margin: 1.125em 0 0;
}

.description__property:not(:first-child) {
  margin-top: .25em;
}

.description__property-name {
  margin: 0 .5em 0 0;
}

@media only screen and (max-width: 768px) {
  .description__header {
    margin: 0 0 1.5em;
  }
}

.minting-form {
  text-align: center;
}

.minting-form__content {
  padding: 1.875em 2.5em;
}

.minting-form__button-container {
  background: url("Coins.a69ffa54.png") no-repeat, url("Coins.a69ffa54.png") 100% no-repeat;
  margin: 2em auto;
  padding: .3125em 2.75em;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .minting-form__content {
    padding: 1.25em .625em;
  }

  .minting-form__button-container {
    margin: 1em auto;
  }
}

.portrait-preview__tip {
  background: url("Eyes.d3786df8.png") 100% no-repeat;
  margin: 1em 0 0;
  padding: .3125em 3em .3125em 0;
  display: inline-block;
}

.portrait-preview__img {
  width: 100%;
  aspect-ratio: 9 / 16;
}

.hobbies-form {
  padding: 1.125em 1em;
}

.hobbies-form__title {
  margin: 0 0 1.125em;
}

.hobbies-form__button-container {
  background: url("Pallet.10e2a4c1.png") 0 no-repeat, url("Pallet.10e2a4c1.png") 100% no-repeat;
  margin: 1.5em 0;
  padding: .3125em 2.75em;
  display: inline-block;
}

.hobbies-form__last-p {
  margin: 1.125em 0 0;
}

@media only screen and (max-width: 768px) {
  .hobbies-form {
    padding: 1.125em .625em;
  }

  .hobbies-form__title {
    margin: 0 0 .75em;
  }

  .hobbies-form__button-container {
    margin: 1em 0;
  }

  .hobbies-form__last-p {
    margin: .75em 0 0;
  }
}

@media only screen and (max-width: 480px) {
  .hobbies-form__description {
    display: inline;
  }
}

.portrait__video {
  background: #ffffcd;
}

.portrait__video_vertical {
  aspect-ratio: 9 / 16;
}

.portrait__info {
  justify-content: space-between;
  align-items: flex-start;
  margin: 1em 0 0;
  display: flex;
}

.portrait__info_only-sound-button {
  justify-content: flex-end;
}

.portrait__full-resolution-links {
  margin: 0 0 0 .5em;
}

.portrait__volume-button {
  cursor: pointer;
  background: none;
  border: none;
}

.portrait__volume-button_state_on .portrait__turned-on-icon, .portrait__volume-button_state_off .portrait__turned-off-icon {
  display: block;
}

.portrait__turned-on-icon, .portrait__turned-off-icon {
  width: 2em;
  display: none;
}

@media only screen and (max-width: 768px) {
  .portrait__info {
    margin: .5em 0 0;
  }

  .portrait__turned-on-icon, .portrait__turned-off-icon {
    width: 1.5em;
  }
}

.minting-painting-form {
  text-align: center;
  padding: 1.125em 1em;
}

.minting-painting-form__title {
  margin: 0 0 1.125em;
}

.minting-painting-form__description {
  letter-spacing: -.03125em;
}

.minting-painting-form__button-container {
  background: url("Pallet.10e2a4c1.png") 0 no-repeat, url("Pallet.10e2a4c1.png") 100% no-repeat;
  margin: 1.125em 0;
  padding: .3125em 2.75em;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .minting-painting-form {
    padding: 1.125em .625em;
  }

  .minting-painting-form__title {
    margin: 0 0 1em;
  }

  .minting-painting-form__button-container {
    margin: 1em 0;
  }
}

.contacts {
  text-align: center;
  background: url("Mail.e56c8292.png") 1em 50% / 2em no-repeat, #ffffcd url("Mail.e56c8292.png") right 1em center / 2em no-repeat;
  padding: .875em 3.5em;
}

.contacts__p:not(:first-child) {
  margin: .25em 0 0;
}

@media only screen and (max-width: 768px) {
  .contacts {
    background: url("Mail.e56c8292.png") 50% 1em / 2em no-repeat, #ffffcd url("Mail.e56c8292.png") center bottom 1em / 2em no-repeat;
    padding: 3.625em .625em;
  }

  .contacts__p:not(:first-child) {
    margin: .75em 0 0;
  }
}

.photos {
  text-align: center;
  padding: 2.125em 4.75em;
}

.photos__title {
  background: url("Camera icon right.5d62bcf6.png") 0 / 2em no-repeat, url("Camera icon right.5d62bcf6.png") 100% / 2em no-repeat;
  margin: 0 0 2em;
  padding: .3125em 2.75em;
  display: inline-block;
}

.photos__gallery {
  width: calc(100% + 2.5em);
  flex-flow: wrap;
  margin: -2.5em 0 0 -2.5em;
  display: flex;
}

.photos__item {
  max-width: 7.5em;
  max-height: 7.5em;
  margin: 2.5em 0 0 2.5em;
}

.photos__img {
  max-width: 100%;
  max-height: 100%;
}

.photos__description {
  margin: 2.5em 0 0;
}

@media only screen and (max-width: 992px) {
  .photos__gallery {
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .photos {
    padding: 1.125em .625em;
  }

  .photos__title {
    margin: 0 0 1.5em;
  }

  .photos__description {
    margin: 1.5em 0 0;
  }

  .photos__gallery {
    width: calc(100% + 1.25em);
    margin: -1.25em 0 0 -1.25em;
  }

  .photos__item {
    margin: 1.25em 0 0 1.25em;
  }
}

@media only screen and (max-width: 480px) {
  .photos__gallery {
    flex-flow: column;
    align-items: center;
  }
}

.gallery {
  text-align: center;
  padding: 2.125em 1.5em;
}

.gallery__title {
  background: url("Brush icon right.6b7d524f.png") 0 / 2em no-repeat, url("Brush icon right.6b7d524f.png") 100% / 2em no-repeat;
  margin: 0 0 2em;
  padding: .3125em 2.75em;
  display: inline-block;
}

.gallery__items-container {
  width: calc(100% + .5em);
  flex-flow: wrap;
  justify-content: center;
  margin: -1.25em 0 0 -.5em;
  display: flex;
}

.gallery__item {
  max-width: 12.5em;
  margin: 1.25em 0 0 .5em;
}

.gallery__img-container {
  width: 12.5em;
  max-width: 12.5em;
  height: 9.25em;
  max-height: 9.25em;
  cursor: default;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.gallery__img {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.gallery__comment {
  margin: .5em 0 0;
}

.gallery__description {
  margin: 2.5em 0 0;
}

@media only screen and (max-width: 768px) {
  .gallery {
    padding: 1.125em .625em;
  }

  .gallery__title {
    margin: 0 0 1.5em;
  }

  .gallery__description {
    margin: 1.5em 0 0;
  }
}

.entry {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.entry__logo {
  max-width: 94.1691%;
  margin: 3.125em auto;
  display: block;
}

.entry__portrait {
  width: 100%;
}

.entry__enter-block {
  text-align: center;
  margin: 2.75em auto 0;
}

.portrait-loader {
  text-align: center;
}

.portrait-loader__box {
  width: 21.875em;
  max-width: 100%;
  margin: 8.375em auto 0;
}

.portrait-loader__box-content {
  flex-flow: column;
  align-items: center;
  padding: 1.625em 2.125em;
  display: flex;
}

.portrait-loader__hourglass {
  width: 8em;
  margin: 0 0 1.25em;
}

@media only screen and (max-width: 768px) {
  .portrait-loader__box {
    margin: 3.75em auto 0;
  }
}

.portrait-section {
  display: flex;
}

.portrait-section__preview {
  width: 100%;
  max-width: 400px;
  flex-shrink: 1;
  margin: 0 2.5em 0 0;
}

.portrait-section__description {
  max-width: 400px;
}

.portrait-section__minting-form {
  margin: 2.5em 0 0;
}

@media only screen and (max-width: 992px) {
  .portrait-section__preview {
    min-width: 20em;
    margin: 0 1.5em 0 0;
  }

  .portrait-section__description {
    min-width: 23.75em;
    max-width: 100%;
    flex-shrink: 1.3;
  }
}

@media only screen and (max-width: 768px) {
  .portrait-section__preview {
    min-width: 17.5em;
    margin: 0 1.5em 0 0;
  }

  .portrait-section__description {
    min-width: 18.75em;
    max-width: 100%;
    flex-shrink: 1.5;
  }

  .portrait-section__minting-form {
    margin: 1.5em 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .portrait-section {
    flex-flow: column;
  }

  .portrait-section__preview {
    min-width: auto;
    max-width: 100%;
    margin: 0 0 2em;
  }

  .portrait-section__description {
    min-width: auto;
  }
}

.hobbies-section__form, .minting-painting-section__form {
  max-width: 39.375em;
  margin: 0 auto;
}

@media only screen and (max-width: 425px) {
  .minting-painting-section__form {
    box-sizing: border-box;
    max-width: 21.5625em;
  }
}

.painting-section {
  text-align: center;
}

.painting-section__img {
  width: 100%;
}

.painting-section__title {
  margin: 1.5em 0 .5em;
}

.painting-section__view-link {
  margin: .5em 0 0;
}

@media only screen and (max-width: 768px) {
  .painting-section__title {
    margin: 1.125em 0 .5em;
  }
}

.collection-section__title {
  text-align: center;
  margin: 0 0 2.5em;
}

.collection-section__fake-image {
  display: none !important;
}

.collection-section .plyr {
  min-width: auto;
}

.collection-section .plyr, .collection-section__img {
  width: calc(100% + .75em);
  max-width: calc(100% + .75em);
  margin: -.375em 0 0 -.375em;
}

.collection-section__gallery {
  opacity: 0;
  visibility: hidden;
  transition: opacity .1s ease-in-out;
}

.collection-section__gallery_visible {
  opacity: 1;
  visibility: visible;
}

.collection-section__item {
  position: relative;
}

.collection-section__item:hover .collection-section__caption[class][class] {
  display: block;
}

.collection-section__link {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

.collection-section__caption[class][class] {
  z-index: 10;
  display: none;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
  .collection-section__title {
    text-align: center;
    margin: 0 0 2em;
  }
}

.about-section {
  text-align: center;
}

.about-section__block:not(:first-child) {
  margin: 2.5em auto 0;
}

.about-section__title {
  margin: 0 0 1.125em;
}

.about-section__p:not(:first-child) {
  margin: 1.5em 0 0;
}

.about-section__contacts {
  max-width: 34.5em;
}

.about-section__last-prase {
  max-width: 23.5em;
}

.about-section__video {
  max-width: 40.75em;
  margin: 0 auto;
}

.about-section__chainsaw-link {
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .about-section__block:not(:first-child) {
    margin: 2em auto 0;
  }

  .about-section__p:not(:first-child) {
    margin: 1em 0 0;
  }

  .about-section__contacts {
    max-width: 21.5em;
  }
}

@media only screen and (max-width: 640px) {
  .about-section__author {
    max-width: 19em;
    margin-left: auto;
    margin-right: auto;
  }

  .about-section__contacts-by {
    max-width: 18.125em;
    margin-left: auto;
    margin-right: auto;
  }

  .about-section__calligraphy[class][class] {
    max-width: 17.5em;
    margin-left: auto;
    margin-right: auto;
  }

  .about-section__illustrations {
    max-width: 15.625em;
    margin-left: auto;
    margin-right: auto;
  }
}

.extras-section__title {
  margin: 0 0 1.5em;
}

.extras-section__text-block {
  text-align: center;
}

.extras-section__article:not(:first-child) {
  margin: 2.5em 0 0;
}

.extras-section__block:not(:first-child) {
  margin: 1.5em 0 0;
}

.extras-section__p:not(:first-child) {
  margin: 1.125em 0 0;
}

.extras-section__centered-img[class][class] {
  margin-left: auto;
  margin-right: auto;
}

.extras-section__music-link, .extras-section__video-link {
  padding: .3125em 0 .3125em 2.5em;
  display: inline-block;
}

.extras-section__music-link {
  background: url("Music icon.63ef7d71.png") 0 / 2em no-repeat;
}

.extras-section__video-link {
  background: url("Movie icon.38165db0.png") 0 / 2em no-repeat;
}

@media only screen and (max-width: 768px) {
  .extras-section__article:not(:first-child) {
    margin: 2em 0 0;
  }

  .extras-section__block:not(:first-child) {
    margin: 1em 0 0;
  }
}

.page {
  min-width: 320px;
  min-height: 100vh;
  background: url("background.ba9f37fe.png");
  padding: 0 0 1.5em;
}

.page_tree {
  min-height: 100vh;
  flex-flow: column;
  padding: 0;
  display: flex;
}

.page_tree .page__main {
  flex-grow: 1;
}

.page_tree .page__main_for-map {
  flex-flow: column;
  display: flex;
}

.page_tree .page__main_for-map #map {
  flex-grow: 1;
}

.page_tree .page__header {
  flex-grow: 0;
}

@media only screen and (max-width: 768px) {
  .page {
    padding: 0 0 1em;
  }

  .page_tree {
    padding: 0;
  }
}

.linage {
  fill: none;
  stroke: #817b58;
  stroke-width: 4px;
  stroke-linejoin: round;
}

.marriage {
  fill: none;
  stroke: #817b58;
  stroke-width: 4px;
}

.nodeText {
  font: 10px sans-serif;
}

.marriageNode {
  background-color: #000;
  border-radius: 50%;
}

.graph .gallery-player {
  width: 100%;
}

.graph .node {
  justify-content: center;
  align-items: center;
  display: flex;
}

.graph .content, .graph .spouse {
  max-width: 150px;
}

.graph .graph-image-wrapper, .graph .graph-player {
  width: auto;
  min-width: auto;
  max-width: 100%;
  max-height: 100%;
  background: #ffffcd;
  border: 4px solid #817b58;
  border-radius: 2px;
}

.graph .plyr__video-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.graph .plyr {
  min-width: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 2px;
}

.graph .plyr video {
  width: auto;
  height: auto;
}

.graph #graph-data {
  visibility: hidden;
  z-index: -1;
  position: absolute;
  left: -400vw;
}

/*# sourceMappingURL=index.9e046582.css.map */
