@import "../helpers/utils";

.portrait-loader {
  text-align: center;

  &__box {
    margin: em(134) auto 0 auto;
    width: em(350);
    max-width: 100%;
  }

  &__box-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: em(26) em(34);
  }

  &__hourglass {
    margin: 0 0 em(20) 0;
    width: em(128);
  }

  @include media($tabletL) {
    &__box {
      margin: em(60) auto 0 auto;
    }
  }
}